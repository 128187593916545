const getters = {
  navActive: state => state.app.navActive,
  bannerList: state => state.app.banners,
  bannerDetail: state => state.app.bannerDetail,
  homeTypeList: state => state.cniyv.homeTypeList,
  cniyvTypeList: state => state.cniyv.typeList,
  cniyvList: state => state.cniyv.cniyvList,
  cniyvMsg: state => state.cniyv.cniyvMsg,
  newsList: state => state.news.newsList,
  newsInfo: state => state.news.newsInfo,
  caseList: state => state.cases.caseList,
  caseInfo: state => state.cases.caseInfo,
}
export default getters