<template>
  <div id="app">
    <router-view></router-view>
    <!-- <component-home /> -->
  </div>
</template>

<script>
// import ComponentHome from './components/ComponentHome.vue';

export default {
  name: 'App',
  // components: {
  //   ComponentHome
  // },
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto; */
  height: 100%;
}
</style>
