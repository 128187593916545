import { getHomeCniyvTypes, getCniyvTypes, getCniyvList, getCniyvDetail } from "@/api"

const state = {
  homeTypeList: [],
  typeList: [],
  cniyvList: '',
  cniyvMsg: ''
}

const mutations = {
  SET_HOME_TYPE_LIST: (state, payload) => {
    state.homeTypeList = payload
  },
  SET_TYPE_LIST: (state, payload) => {
    state.typeList = payload
  },
  SET_CNIYV_LIST: (state, payload) => {
    state.cniyvList = payload
  },
  SET_CNIYV_MSG: (state, payload) => {
    state.cniyvMsg = payload
  },
}

const actions = {
  getHomeCniyvTypeList({ commit }, option) {
    return new Promise((resolve, reject) => {
      getHomeCniyvTypes(option).then(res => {
        commit('SET_HOME_TYPE_LIST', res.data)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

  getCniyvTypeList({ commit }, option) {
    return new Promise((resolve, reject) => {
      getCniyvTypes(option).then(res => {
        commit('SET_TYPE_LIST', res.data.list)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

  getCniyvLists({ commit }, option) {
    return new Promise((resolve, reject) => {
      getCniyvList(option).then(res => {
        commit('SET_CNIYV_LIST', res.data)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

  getCniyvMsg({ commit }, id) {
    return new Promise((resolve, reject) => {
      getCniyvDetail(id).then(res => {
        res.data.imgs = res.data.imgs ? res.data.imgs.split(',') : []
        // localStorage.setItem('lastChecked', res.data.type)
        commit('SET_CNIYV_MSG', res.data)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}