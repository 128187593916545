import Vue from 'vue'
import store from '@/store/index'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Layout = () => import('../components/Layout.vue')
const Home = () => import('@/views/Home')
const Profuile = () => import('@/views/Profuile')
const Products = () => import('@/views/Products')
const About = () => import('@/views/About')
const News = () => import('@/views/News')
const Applications = () => import('@/views/Applications')
const Business = () => import('@/views/Business')
// const Join = () => import('@/views/Join')
const ProductDetail = () => import('@/views/ProductDetail')
const NewsDetail = () => import('@/views/NewsDetail')
const ApplicationDetail = () => import('@/views/ApplicationDetail')
const PublicDetail = () => import('@/views/PublicDetail')

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      title: '首页'
    },
    component: Layout,
    children: [
      {
        path: '/home',
        meta: {
          title: '北斗天云'
        },
        component: Home,
      },
      {
        path: '/profuiles',
        meta: {
          title: '关于天云'
        },
        component: Profuile,
      },
      {
        path: '/products',
        name: 'products',
        meta: {
          title: '产品中心'
        },
        component: Products,
        children: [
          {
            path: '/products/detail/:id',
            name: 'product-detail',
            meta: {
              isDetail: true
            },
            component: ProductDetail,
          },
        ]
      },
      {
        path: '/applications',
        name: 'applications',
        meta: {
          title: '应用方案'
        },
        component: Applications,
        children: [
          {
            path: '/applications/detail/:id',
            name: 'application-detail',
            meta: {
              isDetail: true
            },
            component: ApplicationDetail,
          },
        ]
      },
      {
        path: '/about',
        meta: {
          title: '联系我们'
        },
        component: About,
      },
      {
        path: '/news',
        meta: {
          title: '新闻动态'
        },
        component: News,
        children: [
          {
            path: '/news/detail/:id',
            name: 'news-detail',
            meta: {
              isDetail: true
            },
            component: NewsDetail,
          },
        ]
      },
      {
        path: '/business',
        meta: {
          title: '核心业务'
        },
        component: Business,
        children: [
          {
            path: '/business/detail/:id',
            name: 'business-detail',
            meta: {
              isDetail: true
            },
            component: ApplicationDetail,
          },
        ]
      },
      
      // {
      //   path: '/join',
      //   meta: {
      //     title: '品牌招商'
      //   },
      //   component: Join,
      // },
      {
        path: '/detail/:id',
        name: '',
        meta: {
          isPublicDetail: true
        },
        component: PublicDetail
      }
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: __dirname,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.meta.title && !to.meta.isDetail) {
    window.document.title = to.meta.title
  } 
  if (JSON.stringify(to.query) == '{}' && JSON.stringify(to.params) == '{}') {
    store.commit('app/SET_NAV_ACTIVE', to.path)
    localStorage.setItem('menuPath', to.path)
  }


  next()
})

export default router;
