import { getNewsList, getNewsDetail } from "@/api"

const state = {
  newsList: '',
  newsInfo: ''
}

const mutations = {
  SET_NEWS_LIST: (state, payload) => {
    state.newsList = payload
  },
  
  SET_NEWS_INFO: (state, payload) => {
    state.newsInfo = payload
  },
}

const actions = {
  getNewsLists({commit}, options) {
    return new Promise((resolve, reject) => {
      getNewsList(options).then(res => {
        commit('SET_NEWS_LIST', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getNewsInfo({commit}, id) {
    return new Promise((resolve, reject) => {
      getNewsDetail(id).then(res => {
        commit('SET_NEWS_INFO', res.data)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}