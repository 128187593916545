import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
// import 'animate.css'
// import '@/assets/common.css'
// import '@/assets/common-cn.css'
// import '@/assets/home.css'
// import '@/assets/index.css'
// import '@/plug/bootstrap-3.2.0-dist/css/bootstrap.min.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/plug/Font-Awesome-4.7.0/css/font-awesome.min.css";
import "@/assets/css/main.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import "@/assets/js/main";
import "element-ui/lib/theme-chalk/index.css";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

Vue.use(Viewer, {
  defaultOptions: {
    button: false,
    toolbar: false,
    fullscreen: false,
    navbar: false,
    movable: false,
    zoomable: false,
    rotatable: false,
    title: false
  }
});

Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
