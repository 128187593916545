import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import cniyv from './modules/cniyv'
import news from './modules/news'
import cases from './modules/cases'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true,
  modules: {
    app,
    cniyv,
    news,
    cases
  },
  getters
})

export default store