import request from '@/utils/request'

/* 获取首页轮播图 */
export function getBanners(params) {
  return request({
    url: '/cniyv/cniyvbanner/page',
    method: 'get',
    params
  })
}

/* 获取对应轮播详情 */
export function getBannerDetail(id) {
  return request({
    url: `/cniyv/cniyvbanner/info/${id}`,
    method: 'get'
  })
}

/* 获取首页推荐产品类别及对应产品 */
export function getHomeCniyvTypes(params) {
  return request({
    url: '/cniyv/cniyvproducttype/listProduct',
    method: 'get',
    params
  })
}

/* 获取所有产品类别 */
export function getCniyvTypes(params) {
  return request({
    url: '/cniyv/cniyvproducttype/page',
    method: 'get',
    params
  })
}

/* 根据类别获取对应产品列表 */
export function getCniyvList(params) {
  return request({
    url: '/cniyv/cniyvproduct/page',
    method: 'get',
    params
  })
}

/* 获取对应产品详情 */
export function getCniyvDetail(id) {
  return request({
    url: `/cniyv/cniyvproduct/info/${id}`,
    method: 'get'
  })
}

/* 获取新闻动态列表 */
export function getNewsList(params) {
  return request({
    url: '/cniyv/cniyvarticle/page',
    method: 'get',
    params
  })
}

/* 获取对应新闻动态详情 */
export function getNewsDetail(id) {
  return request({
    url: `/cniyv/cniyvarticle/info/${id}`,
    method: 'get'
  })
}

/* 获取应用方案/核心业务列表 */
export function getCaseList(params) {
  return request({
    url: '/cniyv/cniyvcase/page',
    method: 'get',
    params
  })
}

/* 获取对应应用方案/核心业务详情 */
export function getCaseDetail(id) {
  return request({
    url: `/cniyv/cniyvcase/info/${id}`,
    method: 'get'
  })
}
