import { getCaseDetail, getCaseList } from "@/api"

const state = {
  caseList: '',
  caseInfo: ''
}

const mutations = {
  SET_CASE_LIST: (state, payload) => {
    state.caseList = payload
  },
  
  SET_CASE_INFO: (state, payload) => {
    state.caseInfo = payload
  },
}

const actions = {
  getCaseLists({commit}, options) {
    return new Promise((resolve, reject) => {
      getCaseList(options).then(res => {
        commit('SET_CASE_LIST', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getCaseInfo({commit}, id) {
    return new Promise((resolve, reject) => {
      getCaseDetail(id).then(res => {
        commit('SET_CASE_INFO', res.data)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}