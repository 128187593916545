import { getBanners, getBannerDetail } from "@/api"

const state = {
  navActive: '/',
  breadList: [],
  banners: [],
  bannerDetail: ''
}

const mutations = {
  SET_NAV_ACTIVE: (state, payload) => {
    state.navActive = payload
  },

  SET_BREAD_LIST: (state, payload) => {
    state.breadList = payload
  },

  SET_BANNERS: (state, payload) => {
    state.banners = payload
  },

  SET_BANNER_DETAIL: (state, payload) => {
    state.bannerDetail = payload
  },
}

const actions = {
  setNavPath({ commit }, payload) {
    commit('SET_NAV_ACTIVE', payload)
  },

  setBreadList({ commit }, payload) {
    commit('SET_BREAD_LIST', payload)
  },

  getBannerList({commit}, option) {
    return new Promise((resolve, reject) => {
      getBanners(option).then(res => {
        commit('SET_BANNERS', res.data.list)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

  getBannerMsg({ commit }, id) {
    return new Promise((resolve, reject) => {
      getBannerDetail(id).then(res => {
        res.data.imgs = res.data.imgs ? res.data.imgs.split(',') : []
        commit('SET_BANNER_DETAIL', res.data)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}